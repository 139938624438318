.website-settings-main-container {
    background-color: white;
    padding: 35px;
    border-radius: 24px;
  }
  .zero-margin {
    margin: 0px !important;
  }
  .scroll-y {
    overflow-y:scroll;
    max-height: 220px;
  }
  .caption {
    display: flex !important;    
  }
  .max-caption {
    background-color: #23B2D9 !important;
  }
  .min-caption {
    background-color: #23B2D9 !important;
  }
  .side-bar {
    background-color: #23B2D9 !important;
  }
  *{
      -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
      /* display: none; */
  }
  .center {
    align-items: center !important;
    justify-content: center !important;
  }
.d-block {
  display: block !important;
  margin-left: 10%;
  margin-right: 10%;
}
.d-none {
  display: none !important;
}
.nav-item .nav-link.active {
  background-color: #ffffff !important;
  color: #23B2D9 !important;
}

.nav-item .nav-link.active .nav-icon { 
  color: #23B2D9 !important;
}
.summary-item { 
  font-size: large;
}

.summary-item-deep-font {
 font-weight: bold !important;
 text-decoration: underline;
 cursor: pointer;
}

.font-bold {
  font-weight: bolder !important;
  cursor: pointer;
}

.warehouse-search-col {
  margin-bottom: 24px !important;
  flex-direction: 'row' !important;
  display: 'flex' !important;
  align-items: "center" !important;
}

.supply-select-all { 
  font-size: medium;
  font-weight: bold !important;
  cursor: 'pointer' !important;
  padding-top: 4px !important;
  padding-left: 8px !important;
}

.supply-select-all:hover { 
  background-color: #23B2D9;
  color: white;
  cursor: 'pointer' !important;
}

.supply-select-item { 
  background-color: #23B2D9;
  color: white;
  cursor: 'pointer' !important;
}
.modal-header {
  background-color: #23B2D9;
  color: white;
}
.red-text {
  color: 'red' !important;
}
.max-width {
  width: -webkit-fill-available !important;
  width: -moz-available !important; /* Firefox */
}