@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@600&display=swap');
:root {
  --cui-form-check-input-checked-bg-color : #23B2D9;
  --cui-form-check-input-checked-border-color: #23B2D9;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  font-size: .9rem !important;
  font-weight:500 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'League Spartan', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100%;
}
html, body {
  height: 100%;
}
