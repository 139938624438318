.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-form-container {
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
    padding: 40px;
    border-radius: 24px;
    position: absolute;
    z-index: 2;
  }
  .signup-form-container {
    align-items: center;
    justify-content: center;
    background-color: white;
    max-height: 85%;
    min-height: 45%;
    overflow-y: scroll;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
    padding: 40px;
    border-radius: 24px;
    position: absolute;
    z-index: 2;
  }
  .bg-image-blurred {
    width: 100%;
    height: 100%;
    background-image: url('https://www.americanwarehouses.com/hubfs/Blog%20Images/AW-Blog-How-to-Get-the-Most-From-Your-Logistics-Company.jpg');/*url('../assets/images/bg1.jpg');*/
    background-size: cover;
    background-position: center;
    
    /* Add the blur effect */
    filter: blur(7px);
    -webkit-filter: blur(7px);
    /* Center and scale the image nicely */
    background-repeat: no-repeat;
  }
  .login-button {
    background-color: #333333 !important;
    border: 1px solid #333333 !important;
    width: 100%;
  }